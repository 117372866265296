import React from 'react';
import { useTranslation } from 'react-i18next';
import { IStandardButton, IStandardButtonBody } from '../../../../dm/buttons.js';
import { ChildrenWithOtherContent } from './helpers/ChildrenWithOtherContent.js';
import {
  ArrowClockwise,
  ArrowLeftSquareFill,
  ArrowRightSquareFill,
  ArrowsFullscreen,
  CalendarEventFill,
  CaretDown,
  CaretDownFill,
  CaretLeft,
  CaretLeftFill,
  CaretRight,
  CaretRightFill,
  CaretUp,
  CaretUpFill,
  ChevronContract,
  ChevronExpand,
  Circle,
  CircleFill,
  ClipboardFill,
  Copy,
  Floppy2Fill,
  FullscreenExit,
  Palette,
  PencilFill,
  PinAngle,
  PinFill,
  Search,
  TrashFill,
  XCircleFill,
} from 'react-bootstrap-icons';
import { SHXIcon } from '@soulhx/ui-common';
import { ReactNode } from 'react';
import { SHXButton } from './SHXButton.js';

export * from './SHXButton.js';

const CancelBtnBody = ({ text, showDefaultText }: IStandardButtonBody) => {
  const { t } = useTranslation();

  if (showDefaultText && !text) {
    return <ChildrenWithOtherContent text={t('commonComponents.buttons.cancel.btnText')} icon={<XCircleFill />} />;
  }

  return (
    <ChildrenWithOtherContent
      text={text}
      icon={<XCircleFill />}
      i18nKey="commonComponents.buttons.cancel.withContent"
    />
  );
};

const DeleteBtnBody = ({ text, showDefaultText }: IStandardButtonBody) => {
  const { t } = useTranslation();

  if (showDefaultText && !text) {
    return <ChildrenWithOtherContent text={t('commonComponents.buttons.delete.btnText')} icon={<TrashFill />} />;
  }

  return (
    <ChildrenWithOtherContent text={text} icon={<TrashFill />} i18nKey="commonComponents.buttons.delete.withContent" />
  );
};

const EditBtnBody = ({ text }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={<PencilFill />} />;
};

const CopyToClipboardBody = ({ text }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={<ClipboardFill />} />;
};

const CalBtnBody = ({ text }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={<CalendarEventFill />} />;
};

const ColoursBtnBody = ({ text }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={<Palette />} />;
};

const SaveBtnBody = ({ text, showDefaultText }: IStandardButtonBody) => {
  const { t } = useTranslation();

  if (showDefaultText && !text) {
    return <ChildrenWithOtherContent text={t('commonComponents.buttons.save.btnText')} icon={<Floppy2Fill />} />;
  }

  return (
    <ChildrenWithOtherContent text={text} icon={<Floppy2Fill />} i18nKey="commonComponents.buttons.save.withContent" />
  );
};

const RefreshBtnBody = ({ text, showDefaultText }: IStandardButtonBody) => {
  const { t } = useTranslation();

  if (showDefaultText && !text) {
    return <ChildrenWithOtherContent text={t('commonComponents.buttons.refresh.btnText')} icon={<ArrowClockwise />} />;
  }

  return (
    <ChildrenWithOtherContent
      text={text}
      icon={<ArrowClockwise />}
      i18nKey="commonComponents.buttons.refresh.withContent"
    />
  );
};

const DuplicateBtnBody = ({ text, showDefaultText }: IStandardButtonBody) => {
  const { t } = useTranslation();

  if (showDefaultText && !text) {
    return <ChildrenWithOtherContent text={t('commonComponents.buttons.dup.btnText')} icon={<Copy />} />;
  }

  return <ChildrenWithOtherContent text={text} icon={<Copy />} i18nKey="commonComponents.buttons.dup.withContent" />;
};

const CompleteBtnBody = ({ text, done }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={done ? <CircleFill /> : <Circle />} />;
};

const PinBtnBody = ({ text, done }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={done ? <PinFill /> : <PinAngle />} />;
};

const FullScreenBtnBody = ({ text, done }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={done ? <FullscreenExit /> : <ArrowsFullscreen />} />;
};

const OpenCloseBibleBtnBody = ({ text, done }: IStandardButtonBody) => {
  return (
    <ChildrenWithOtherContent text={text} icon={done ? <SHXIcon type="bibleopen" /> : <SHXIcon type="bibleclosed" />} />
  );
};

const ShowHideBtnBody = ({ text, done }: IStandardButtonBody) => {
  return (
    <ChildrenWithOtherContent
      i18nKey="commonComponents.buttons.showHide.children"
      text={text}
      icon={done ? <ChevronContract /> : <ChevronExpand />}
    />
  );
};

const LeftBtnBody = ({ text, fill = true }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={fill ? <CaretLeftFill /> : <CaretLeft />} />;
};

const RightBtnBody = ({ text, fill = true }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={fill ? <CaretRightFill /> : <CaretRight />} />;
};

const UpBtnBody = ({ text, fill }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={fill ? <CaretUpFill /> : <CaretUp />} />;
};

const DownBtnBody = ({ text, fill }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={fill ? <CaretDownFill /> : <CaretDown />} />;
};

const SearchBtnBody = ({ text }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={<Search />} />;
};

const NextBtnBody = ({ text }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={<ArrowRightSquareFill />} />;
};

const PrevBtnBody = ({ text }: IStandardButtonBody) => {
  return <ChildrenWithOtherContent text={text} icon={<ArrowLeftSquareFill />} />;
};

export const StandardButton = ({
  children,
  type,
  variant,
  className,
  disabled,
  onClick,
  tooltip,
  submit,
  size,
  itemType,
  done,
  fill,
  showDefaultText = false,
}: IStandardButton) => {
  const { t } = useTranslation();

  const btnProps: IStandardButtonBody = { text: children, done, fill, showDefaultText };

  let renderedChildren: ReactNode | undefined = children;
  let renderedTooltip: string | undefined = tooltip;
  if (!renderedTooltip) {
    renderedTooltip = t(`commonComponents.buttons.${type}.tooltip`, { itemType });
  }

  let renderedVariant: string | undefined = variant;

  switch (type) {
    case 'cancel': {
      renderedChildren = CancelBtnBody(btnProps);
      break;
    }
    case 'delete': {
      renderedChildren = DeleteBtnBody(btnProps);
      if (!renderedVariant) renderedVariant = 'outline-danger';
      break;
    }
    case 'edit': {
      renderedChildren = EditBtnBody(btnProps);
      break;
    }
    case 'copyToClipboard': {
      renderedChildren = CopyToClipboardBody(btnProps);
      break;
    }
    case 'colours': {
      renderedChildren = ColoursBtnBody(btnProps);
      break;
    }
    case 'save': {
      renderedChildren = SaveBtnBody(btnProps);
      break;
    }
    case 'refresh': {
      renderedChildren = RefreshBtnBody(btnProps);
      break;
    }
    case 'duplicate': {
      renderedChildren = DuplicateBtnBody(btnProps);
      break;
    }
    case 'complete': {
      renderedChildren = CompleteBtnBody(btnProps);
      break;
    }
    case 'pin': {
      renderedChildren = PinBtnBody(btnProps);
      break;
    }
    case 'openCloseBible': {
      renderedChildren = OpenCloseBibleBtnBody(btnProps);
      break;
    }
    case 'showHide': {
      renderedChildren = ShowHideBtnBody(btnProps);
      break;
    }
    case 'left': {
      renderedChildren = LeftBtnBody(btnProps);
      if (!renderedVariant) renderedVariant = 'outline-dark';
      break;
    }
    case 'right': {
      renderedChildren = RightBtnBody(btnProps);
      if (!renderedVariant) renderedVariant = 'outline-dark';
      break;
    }
    case 'up': {
      renderedChildren = UpBtnBody(btnProps);
      if (!renderedVariant) renderedVariant = 'outline-dark';
      break;
    }
    case 'down': {
      renderedChildren = DownBtnBody(btnProps);
      if (!renderedVariant) renderedVariant = 'outline-dark';
      break;
    }
    case 'calendar': {
      renderedChildren = CalBtnBody(btnProps);
      break;
    }
    case 'search': {
      renderedChildren = SearchBtnBody(btnProps);
      break;
    }
    case 'next': {
      renderedChildren = NextBtnBody(btnProps);
      break;
    }
    case 'prev': {
      renderedChildren = PrevBtnBody(btnProps);
      break;
    }
    case 'fullscreen': {
      renderedChildren = FullScreenBtnBody(btnProps);
      break;
    }
    default: {
      return <></>;
    }
  }

  if (!renderedVariant) renderedVariant = 'outline-secondary';

  return (
    <SHXButton
      variant={renderedVariant}
      className={className}
      disabled={disabled}
      onClick={onClick}
      tooltip={renderedTooltip}
      submit={submit}
      size={size}
    >
      {renderedChildren}
    </SHXButton>
  );
};

import React from 'react';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  codeBlockPlugin,
  codeMirrorPlugin,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertTable,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  quotePlugin,
  RealmPlugin,
  sandpackPlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
} from '@mdxeditor/editor';
import { ToolbarStyle } from 'dm/standard-types.js';
import { shxTextPlugins, smartypantsPlugin, shxShortcutsPlugin } from '@soulhx/shxmd-core';
import { bibleLinkPlugin, bibleLinkDialogPlugin, CreateBibleLink } from '@soulhx/shxmd-bl';

export const getPlugins = (
  sourceMode: boolean,
  toolbarStyle: ToolbarStyle,
  defaultVersion?: string,
  passageContext?: string
): RealmPlugin[] => {
  const plugins: RealmPlugin[] = [
    diffSourcePlugin({ viewMode: sourceMode ? 'source' : 'rich-text' }),
    thematicBreakPlugin(),
    headingsPlugin(),
    linkPlugin(),
    linkDialogPlugin(),
    bibleLinkPlugin({ defaultVersion, passageContext }),
    bibleLinkDialogPlugin(),
    tablePlugin(),
    listsPlugin(),
    quotePlugin(),
    smartypantsPlugin(),
    shxTextPlugins(),
    markdownShortcutPlugin(),
    shxShortcutsPlugin(),
    codeBlockPlugin({ defaultCodeBlockLanguage: '' }),
    codeMirrorPlugin({ codeBlockLanguages: { '': 'Plain Text' } }),
    sandpackPlugin({
      sandpackConfig: {
        defaultPreset: 'txt',
        presets: [
          {
            label: 'Text',
            name: 'txt',
            meta: 'Live Text',
            sandpackTemplate: 'vanilla',
            sandpackTheme: 'light',
            initialSnippetContent: '',
            snippetLanguage: 'txt',
            snippetFileName: '',
          },
        ],
      },
    }),
  ];

  if (!sourceMode && (toolbarStyle === 'condensed' || toolbarStyle === 'full')) {
    const tb = toolbarPlugin({
      toolbarContents: () => (
        <DiffSourceToggleWrapper options={['rich-text', 'source']}>
          <UndoRedo />
          <BoldItalicUnderlineToggles options={['Bold', 'Italic']} />
          <BlockTypeSelect />
          <Separator />
          <CreateLink />
          <CreateBibleLink />
          <Separator />
          <ListsToggle />
          <InsertTable />
        </DiffSourceToggleWrapper>
      ),
    });
    plugins.push(tb);
  }

  return plugins;
};

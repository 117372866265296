import React, { useState } from 'react';
import { Navbar, Nav, Offcanvas, NavDropdown } from 'react-bootstrap';
import { Gear } from 'react-bootstrap-icons';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUserDetailsQuery } from '../../services/UserService.js';
import { useTranslation } from 'react-i18next';
import { SHXIcon } from '@soulhx/ui-common';
import { handleInit } from '../../hooks/HandleInit.js';
import { Settings } from './Settings.js';
import { useUserSetting } from '../../hooks/Setting.js';
import { SHXButton } from './ui/buttons/index.js';
import { ShxHelpText } from './ShxHelpText.js';

export const Header = () => {
  const { data, error, isLoading } = useUserDetailsQuery();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const { t } = useTranslation();
  const { settingValue: homeHT } = useUserSetting<boolean>('helpText.home.headerHome');
  const { settingValue: prayerHT } = useUserSetting<boolean>('helpText.home.headerPrayer');
  const { settingValue: readHT } = useUserSetting<boolean>('helpText.home.headerRead');
  const { settingValue: doHT } = useUserSetting<boolean>('helpText.home.headerDo');
  const { settingValue: plansHT } = useUserSetting<boolean>('helpText.home.headerPlans');
  const { settingValue: settingsHT } = useUserSetting<boolean>('helpText.home.settings');
  const navigate = useNavigate();

  const htShowing = homeHT || prayerHT || readHT || doHT || plansHT || settingsHT;

  const isAdmin = data ? data.isAdmin : false;

  const expandSize = htShowing ? 'md' : undefined;
  const brandClass = expandSize ? `d-md-none` : 'd-none';
  const homeClass = expandSize ? 'd-none d-md-inline' : undefined;

  const resp = handleInit([isLoading], [error]);
  if (resp) return resp;

  return (
    <Navbar
      variant="dark"
      bg="primary"
      sticky="top"
      className="p-0"
      style={{ width: '100%' }}
      expand={expandSize}
      collapseOnSelect
    >
      {htShowing && (
        <Nav className={brandClass}>
          <Nav.Item className="mx-1">
            <Nav.Link as={NavLink} eventKey={1} to="/">
              <SHXIcon type="logo" size={30} />
              <ShxHelpText pageName="home" textName="headerHome" inline firstLevel />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      )}
      <Navbar.Toggle aria-controls="soulhx-header-navbar" />
      <Navbar.Collapse id="soulhx-header-navbar">
        <Nav>
          <Nav.Item className={homeClass}>
            <Nav.Link as={NavLink} eventKey={1} to="/" aria-label={t('header.linkNames.home')}>
              <SHXIcon type="logo" className="fs-1" />
              <ShxHelpText pageName="home" textName="headerHome" inline firstLevel />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} eventKey={2} to="/pray" aria-label={t('header.linkNames.prayer')}>
              <SHXIcon type="pray" className="fs-1" />
              <ShxHelpText pageName="home" textName="headerPrayer" inline firstLevel />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} eventKey={3} to="/read" aria-label={t('header.linkNames.read')}>
              <SHXIcon type="journal" className="fs-1" />
              <ShxHelpText pageName="home" textName="headerRead" inline firstLevel />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} eventKey={4} to="/do" aria-label={t('header.linkNames.do')}>
              <SHXIcon type="do" className="fs-1" />
              <ShxHelpText pageName="home" textName="headerDo" inline firstLevel />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} eventKey={5} to="/plans" aria-label={t('header.linkNames.plan')}>
              <SHXIcon type="readinglist" className="fs-1" />
              <ShxHelpText pageName="home" textName="headerPlans" inline firstLevel />
            </Nav.Link>
          </Nav.Item>
          {isAdmin && (
            <NavDropdown
              title={<SHXIcon type="admin" className="fs-1" />}
              id="adminDropdown"
              onSelect={(eventKey) => {
                if (!eventKey) return;
                navigate(eventKey);
              }}
              active={location.pathname.startsWith('/admin')}
            >
              <NavDropdown.Item eventKey="/admin/users">{t('header.linkNames.admin.users')}</NavDropdown.Item>
              <NavDropdown.Item eventKey="/admin/serverMessages">
                {t('header.linkNames.admin.serverMessages')}
              </NavDropdown.Item>
              {/* TODO: remove this item */}
              <NavDropdown.Item eventKey="/admin/mdTester">Markdown Tester</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
      <Navbar.Text className="ms-auto me-1">
        <ShxHelpText pageName="home" textName="settings" collapsed inline />

        <SHXButton
          variant="primary"
          className="text-white p-0"
          onClick={() => {
            if (showSettings) {
              setShowSettings(false);
            } else {
              setShowSettings(true);
            }
          }}
          ariaLabel={t('header.settings')}
        >
          <Gear className="fs-1" />
        </SHXButton>
      </Navbar.Text>

      <Offcanvas
        show={showSettings}
        onHide={() => setShowSettings(false)}
        placement="end"
        aria-labelledby="settingspaneTitle"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="settingspaneTitle">{t('header.settings')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Settings closeFunc={() => setShowSettings(false)} />
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
};

export default Header;

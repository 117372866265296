import React from 'react';
import { DecoratorNode, LexicalEditor, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from 'lexical';
import { FootnoteReferenceEditor } from '../editors/FootnoteReferenceEditor.js';

export type SerializedFootnoteReferenceNode = Spread<
  {
    identifier: string;
    label?: string;
  },
  SerializedLexicalNode
>;

export class FootnoteReferenceNode extends DecoratorNode<JSX.Element> {
  __identifier: string;
  __label?: string;

  constructor(identifier: string, label?: string, key?: NodeKey) {
    super(key);
    this.__identifier = identifier;
    this.__label = label;
  }

  static getType() {
    return 'footnoteReference';
  }

  static clone(node: FootnoteReferenceNode): FootnoteReferenceNode {
    return new FootnoteReferenceNode(node.__identifier, node.__label, node.__key);
  }

  static importJSON(serializedNode: SerializedFootnoteReferenceNode): FootnoteReferenceNode {
    return $createFootnoteReferenceNode(serializedNode.identifier, serializedNode.label);
  }

  exportJSON() {
    return super.exportJSON();
  }

  getIdentifier() {
    return this.__identifier;
  }

  setIdentifier(identifier: string) {
    const writable = this.getWritable();
    writable.__identifier = identifier;
  }

  getLabel() {
    return this.__label;
  }

  setLabel(label: string) {
    const writable = this.getWritable();
    writable.__label = label;
  }

  updateDOM() {
    return false;
  }

  createDOM() {
    const container = document.createElement('sup');
    container.className = 'bg-secondary-subtle';
    return container;
  }

  exportDOM() {
    const element = document.createElement('sup');
    const anchor = document.createElement('a');
    anchor.href = `#user-content-fn-${this.__identifier}`;
    anchor.id = `user-content-fnref-${this.__identifier}`;
    if (this.__label) {
      anchor.append(document.createTextNode(this.__label));
    }
    element.append(anchor);

    return { element };
  }

  decorate(editor: LexicalEditor) {
    return <FootnoteReferenceEditor editor={editor} identifier={this.__identifier} label={this.__label} />;
  }

  isInline() {
    return true;
  }
}

export function $isFootnoteReferenceNode(node: LexicalNode | null | undefined): node is FootnoteReferenceNode {
  return node instanceof FootnoteReferenceNode;
}

export function $createFootnoteReferenceNode(
  identifier: string,
  label?: string | null | undefined
): FootnoteReferenceNode {
  return new FootnoteReferenceNode(identifier, label || undefined);
}

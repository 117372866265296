import React, { useEffect } from 'react';
import { CLICK_COMMAND, COMMAND_PRIORITY_LOW, LexicalEditor } from 'lexical';

export interface IFootnoteReferenceEditor {
  label?: string;
  identifier: string;
  editor: LexicalEditor;
}
export const FootnoteReferenceEditor = ({ label, identifier, editor }: IFootnoteReferenceEditor) => {
  useEffect(() => {
    const unregister = editor.registerCommand<MouseEvent>(
      CLICK_COMMAND,
      () => {
        return true;
      },
      COMMAND_PRIORITY_LOW
    );

    return () => {
      unregister();
    };
  }, [editor]);

  return (
    <a href={`#user-content-fn-${identifier}`} id={`user-content-fnref-${identifier}`}>
      {label || identifier}
    </a>
  );
};

import { createRootEditorSubscription$, realmPlugin } from '@mdxeditor/editor';
import { TextNode } from 'lexical';
import { retext } from 'retext';
import smartypants from 'retext-smartypants';

export const smartypantsPlugin = realmPlugin({
  init(r) {
    r.pub(createRootEditorSubscription$, (editor) => {
      return editor.registerNodeTransform(TextNode, (node) => {
        const processedVFile = retext()
          .use(smartypants, {
            backticks: false,
            dashes: 'oldschool',
          })
          .processSync(node.getTextContent());

        const processedString = String(processedVFile);
        node.setTextContent(processedString);
      });
    });
  },
});

import { ElementNode, LexicalNode, NodeKey, SerializedElementNode, Spread } from 'lexical';

export type SerializedFootnoteDefinitionNode = Spread<
  {
    identifier: string;
    label?: string;
  },
  SerializedElementNode
>;

export class FootnoteDefinitionNode extends ElementNode {
  __identifier: string;
  __label?: string;

  constructor(identifier: string, label?: string, key?: NodeKey) {
    super(key);
    this.__identifier = identifier;
    this.__label = label;
  }

  static getType() {
    return 'footnoteDefinition';
  }

  static clone(node: FootnoteDefinitionNode): FootnoteDefinitionNode {
    return new FootnoteDefinitionNode(node.__identifier, node.__label, node.__key);
  }

  static importJSON(serializedNode: SerializedFootnoteDefinitionNode): FootnoteDefinitionNode {
    return $createFootnoteDefinitionNode(serializedNode.identifier, serializedNode.label);
  }

  exportJSON() {
    return super.exportJSON();
  }

  getIdentifier() {
    return this.__identifier;
  }

  setIdentifier(identifier: string) {
    const writable = this.getWritable();
    writable.__identifier = identifier;
  }

  getLabel() {
    return this.__label;
  }

  setLabel(label: string) {
    const writable = this.getWritable();
    writable.__label = label;
  }

  updateDOM() {
    return false;
  }

  createDOM(): HTMLElement {
    const anchor = document.createElement('section');
    anchor.className = 'bg-secondary-subtle';
    return anchor;
  }

  // decorate(editor: LexicalEditor) {
  //   return <FootnoteDefinitionEditor editor={editor} identifier={this.__identifier} label={this.__label} />;
  // }

  isInline() {
    return true;
  }
}

export function $isFootnoteDefinitionNode(node: LexicalNode | null | undefined): node is FootnoteDefinitionNode {
  return node instanceof FootnoteDefinitionNode;
}

export function $createFootnoteDefinitionNode(
  identifier: string,
  label?: string | null | undefined
): FootnoteDefinitionNode {
  return new FootnoteDefinitionNode(identifier, label || undefined);
}
